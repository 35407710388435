import { v4 } from 'uuid';
import { Ref, ref } from 'vue';
import { Dehydrate, Model } from 'vue-model';
import { NodesSaveAction, NodesMoveAction, DoWhatYouWantAction } from '../actions';
import { Board, Matrix, Point, Node, Group } from '../models';
import { Box, Slide } from '../models/Slide';
import { ChangeSetNonOptional, CREATE, UPDATE, DELETE, ChangeSet, UpdateStep } from './WebSocketMessage';

export function htmlId(id: string) {
  return '_' + id;
}

export function getCoordsFromEvent(ev: any) {
  if (ev.changedTouches) {
    ev = ev.changedTouches[0];
  }
  return new Point({ x: ev.clientX, y: ev.clientY });
}

export function getPointFromEvent(
  ev: MouseEvent | TouchEvent,
  matrix?: Matrix | undefined,
  inverse = false,
  { x = 0, y = 0 } = {},
) {
  const p = getCoordsFromEvent(ev);
  p.x += x;
  p.y += y;

  if (!matrix) return p;

  matrix = inverse && matrix ? matrix.inverse() : matrix;
  return p.transformO(matrix as Matrix);
}

export function minMaxPoints(points: Point[], cb: (xMin: number, xMax: number, yMin: number, yMax: number) => any) {
  let xMin = Infinity;
  let xMax = -Infinity;
  let yMin = Infinity;
  let yMax = -Infinity;

  points.forEach((p) => {
    xMin = Math.min(xMin, p.x);
    xMax = Math.max(xMax, p.x);
    yMin = Math.min(yMin, p.y);
    yMax = Math.max(yMax, p.y);
  });

  return cb(xMin, xMax, yMin, yMax);
}

export function minMaxArray(points: Point[]): number[] {
  return minMaxPoints(points, (...args) => args);
}

export function bboxPoints(points: Point[]): [Point, Point, Point, Point] {
  let xMin = Infinity;
  let xMax = -Infinity;
  let yMin = Infinity;
  let yMax = -Infinity;

  points.forEach((p) => {
    xMin = Math.min(xMin, p.x);
    xMax = Math.max(xMax, p.x);
    yMin = Math.min(yMin, p.y);
    yMax = Math.max(yMax, p.y);
  });

  return minMaxPoints(points, (xMin, xMax, yMin, yMax) => {
    return [
      new Point({ x: xMin, y: yMin }),
      new Point({ x: xMax, y: yMin }),
      new Point({ x: xMax, y: yMax }),
      new Point({ x: xMin, y: yMax }),
    ];
  });
}

const box = (xMin: number, xMax: number, yMin: number, yMax: number): [number, number, number, number] => [
  xMin,
  yMin,
  xMax - xMin,
  yMax - yMin,
];

export function bbox(points: Point[]): number[] {
  return minMaxPoints(points, box);
}

export function pureBBox(points: [Point, Point, Point, Point]) {
  const { x: xMin, y: yMin } = points[0];
  const { x: xMax, y: yMax } = points[2];

  return box(xMin, xMax, yMin, yMax);
}

export function boxToPoints(box: { x: number; y: number; width: number; height: number }) {
  const { x, y, width, height } = box;

  return [
    new Point({ x, y }),
    new Point({ x: x + width, y }),
    new Point({ x: x + width, y: y + height }),
    new Point({ x: x, y: y + height }),
  ];
}

export function hydrater<T extends typeof Model>(a: Dehydrate<T>): InstanceType<T>;
export function hydrater<T extends typeof Model>(a: Dehydrate<T>[]): InstanceType<T>[];
export function hydrater<T extends typeof Model>(a: Dehydrate<T> | Dehydrate<T>[]): InstanceType<T> | InstanceType<T>[];
export function hydrater<T extends typeof Model>(
  a: Dehydrate<T> | Dehydrate<T>[],
): InstanceType<T> | InstanceType<T>[] {
  if (Array.isArray(a)) {
    return a.map((a) => hydrater(a));
  } else {
    return Model.hydrate(a) as InstanceType<T>;
  }
}

export function dehydrater<T extends typeof Model>(a: InstanceType<T>): Dehydrate<T>;
export function dehydrater<T extends typeof Model>(a: InstanceType<T>[]): Dehydrate<T>[];
export function dehydrater<T extends typeof Model>(
  a: InstanceType<T> | InstanceType<T>[],
): Dehydrate<T> | Dehydrate<T>[];
export function dehydrater<T extends typeof Model>(
  a: InstanceType<T> | InstanceType<T>[],
): Dehydrate<T> | Dehydrate<T>[] {
  if (Array.isArray(a)) {
    return a.map((a) => dehydrater(a));
  } else {
    return a.dehydrate();
  }
}

export function radians(d: number) {
  return ((d % 360) * Math.PI) / 180;
}

export function aspectRatioPoints(points: Point[], isLine = false, ignoreFix = false) {
  const p1 = points[points.length - 1];
  const p2 = points[points.length - 2];
  if (!p1 || !p2 || (p1.fixed && !ignoreFix)) return;

  const vec = p1.sub(p2);
  const len = Math.max(Math.abs(vec.x), Math.abs(vec.y));

  const candidates = [p2.add({ x: len * Math.sign(vec.x), y: len * Math.sign(vec.y) } as Point)];

  if (isLine) {
    candidates.push(new Point({ x: p2.x, y: p1.y }), new Point({ x: p1.x, y: p2.y }));
  }

  const mins = candidates.map((p) => p.sub(p1).length());

  const min = Math.min(...mins);
  const index = mins.indexOf(min);

  const { x, y } = candidates[index];
  Object.assign(p1, { x, y });
}

export function paste(copied: Dehydrate<typeof Node>[], board: Board) {
  board.currentSlide?.nodes.forEach((n) => {
    n.selected = false;
  });

  const setBoardId = (nodes: Dehydrate<typeof Node>[]) => {
    nodes.forEach((node) => {
      if ('nodes' in node) {
        setBoardId(node.nodes as any);
      }
      node.boardId = board._id;
    });
  };

  setBoardId(copied);

  const nodes = copied.map((values) => Node.hydrate(values));
  if (!nodes?.length) return;

  nodes.forEach((n) => {
    n.selected = true;
  });

  const action = new DoWhatYouWantAction<{ nodes: Node[]; slide: Slide }>().start();
  action
    .execute({ nodes, slide: board.currentSlide as Slide }, ({ nodes, slide }) => {
      nodes.forEach((n) => {
        slide.addNode(n);
      });
    })
    .then(() => new NodesSaveAction().execute({ nodes }, undefined, 1))
    .then(() => {
      new NodesMoveAction().execute({ nodes }, 20, 20);
    })
    .then(() => {
      action.stop();
      board.copied = nodes.map((n) => {
        return n.dehydrateAsCopy();
      });
    });
}

export const intersects = (a: Box, b: Box) => {
  return (
    Math.abs(a.x + a.width / 2 - (b.x + b.width / 2)) * 2 < a.width + b.width &&
    Math.abs(a.y + a.height / 2 - (b.y + b.height / 2)) * 2 < a.height + b.height
  );
};

const clone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const mergeChangeSet = (newSet: ChangeSetNonOptional, oldSet: ChangeSetNonOptional, backwards = false) => {
  newSet = clone(newSet);
  oldSet = clone(oldSet);

  for (const [modelName, changes] of Object.entries(newSet)) {
    if (!oldSet[modelName]) oldSet[modelName] = {} as ChangeSetNonOptional[string];

    if (!oldSet[modelName][CREATE]) {
      if (changes[backwards ? DELETE : CREATE]) {
        // Creation doesnt exist in oldSet but newSet, so we just copy it
        oldSet[modelName][CREATE] = changes[backwards ? DELETE : CREATE];
      }
    } else {
      // Creation exists in oldSet, so we overwrite every model
      for (const [_id, record] of Object.entries(changes[backwards ? DELETE : CREATE] || {})) {
        oldSet[modelName][CREATE][_id] = record;
        // In case the old set has a deletion for this object we need to remove it
        // because its invalidated by the new creation
        if (oldSet[modelName][DELETE]?.[_id]) {
          delete oldSet[modelName][DELETE][_id];
        }
      }
    }

    if (!oldSet[modelName][DELETE]) {
      if (changes[backwards ? CREATE : DELETE]) {
        // Deletion doesnt exist in oldSet but newSet, so we just copy it
        oldSet[modelName][DELETE] = changes[backwards ? CREATE : DELETE];
      }
    } else {
      // Deletion exists in oldSet, so we overwrite every model
      for (const [_id, record] of Object.entries(changes[backwards ? CREATE : DELETE] || {})) {
        oldSet[modelName][DELETE][_id] = record;
        // In case the old set has a creation for this object we need to remove it
        // because its invalidated by the new deletion
        if (oldSet[modelName][CREATE]?.[_id]) {
          delete oldSet[modelName][CREATE][_id];
        }
      }
    }

    if (!oldSet[modelName][UPDATE]) {
      if (changes[UPDATE]) {
        // Update doesnt exist in oldSet but newSet, so we just copy it
        // For backwards we have to reverse the update steps and swap old and new value

        oldSet[modelName][UPDATE] = {};

        if (backwards) {
          for (const [_id, updates] of Object.entries(changes[UPDATE])) {
            oldSet[modelName][UPDATE][_id] = updates
              .reverse()
              .map(([cmd, key, value, oldValue]) => [cmd, key, oldValue, value]);
          }
        } else {
          oldSet[modelName][UPDATE] = changes[UPDATE];
        }
      }
    } else {
      // Update exists in oldSet, so we overwrite every model
      for (let [_id, record] of Object.entries(changes[UPDATE] || {})) {
        oldSet[modelName][UPDATE][_id] = oldSet[modelName][UPDATE][_id] || [];

        if (backwards) {
          record = record.reverse().map(([cmd, key, value, oldValue]) => [cmd, key, oldValue, value]);
        }

        oldSet[modelName][UPDATE][_id].push(...record);
      }
    }
  }

  cleanUpChangeSet(oldSet);

  return oldSet;
};

export const cleanUpStateChangeSet = (changeSet: ChangeSetNonOptional) => {
  for (const changes of Object.values(changeSet)) {
    if (changes[DELETE]) {
      for (const _id of Object.keys(changes[DELETE])) {
        if (changes[CREATE]?.[_id]) {
          delete changes[CREATE][_id];
          delete changes[DELETE][_id];
        }

        if (changes[UPDATE]?.[_id]) {
          delete changes[UPDATE][_id];
        }
      }
      if (!Object.keys(changes[CREATE] || {}).length) {
        delete (changes as ChangeSet)[CREATE];
      }

      if (!Object.keys(changes[DELETE] || {}).length) {
        delete (changes as ChangeSet)[DELETE];
      }
    }
  }
  return changeSet;
};

export function mergeUpdateSteps(steps: UpdateStep[]) {
  return steps;
  // for (let i = steps.length; i--;) {
  //   const step = steps[i]
  //   const prevStep = steps[i - 1]

  //   if (!prevStep) continue

  //   const cmd = step[0]
  //   const prevCmd = prevStep[0]

  //   if (cmd === '$set' && prevCmd === '$set') {
  //     const [ , key, newValue ] = step
  //     prevStep[2] = Object.assign({}, prevStep[2], { [key]: newValue })
  //     steps.pop()
  //   }
  // }
}

export function cleanUpChangeSet(changeSet: ChangeSet) {
  for (const modelName in changeSet) {
    const changes = changeSet[modelName] as ChangeSetNonOptional[string];
    if (!(UPDATE in changes)) {
      continue;
    }

    for (const id in changes[UPDATE]) {
      const steps = changes[UPDATE][id];
      if (steps.length === 0) {
        delete changes[UPDATE][id];
      }

      mergeUpdateSteps(steps);
    }

    if (changes[CREATE]) {
      for (const id in changes[CREATE]) {
        if (!changes[UPDATE][id]) continue;

        const steps = changes[UPDATE][id];

        for (let i = 0; i < steps.length; ++i) {
          const [cmd, key, value] = steps[i];
          if (cmd !== '$set') break;

          const path = key.split('.');
          const prop = path.pop() as string;

          const m = path.reduce(
            (obj, prop) => {
              if (obj[prop] === undefined) {
                obj[prop] = {};
              }

              return obj[prop] as unknown as Record<string, unknown>;
            },
            changes[CREATE][id] as unknown as Record<string, unknown>,
          );

          m[prop] = value;

          steps.shift();
          --i;
        }

        if (steps.length === 0) {
          delete changes[UPDATE][id];
        }
      }
    }

    if (Object.keys(changes[UPDATE]).length === 0) {
      delete (changes as any)[UPDATE];
    }

    if (Object.keys(changes).length === 0) {
      delete changeSet[modelName];
    }
  }
}

export const getObjByPath = (obj: any, parts: string[]) => {
  let current = obj;
  for (const part of parts) {
    current = current[part];
  }
  return current;
};

export const showConfirmationDialog = ref(false);
export const showPromptDialog = ref(false);
export const showAlertDialog = ref(false);
export const dialogMessage = ref<string | null>(null);
export const yesNoConfirmation = ref(false);
let resolvePromise: ((value: any) => void) | null = null;
let rejectPromise: ((reason?: any) => void) | null = null;
let confirmCallback: ((yesOrNo?: any) => void) | null = null;

export const confirm = (msg: string, cb = (yesOrNo?: boolean): boolean | void => yesOrNo, yesNo = false) => {
  confirmCallback = cb;
  dialogMessage.value = msg;
  yesNoConfirmation.value = yesNo;
  showConfirmationDialog.value = true;

  return new Promise<any>((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });
};

export const dialogOk = (value: any) => {
  return Promise.resolve(confirmCallback?.(value)).then(resolvePromise, rejectPromise);
};

export const prompt = (msg: string, cb = (value?: string) => value) => {
  confirmCallback = cb;
  dialogMessage.value = msg;
  showPromptDialog.value = true;

  return new Promise<string | undefined>((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });
};

export const alert = (msg: string) => {
  dialogMessage.value = msg;
  showAlertDialog.value = true;
};

export function adaptNodeStructureForHydration(node: Dehydrate<typeof Slide | typeof Group>) {
  const change = (parent: Dehydrate<typeof Slide | typeof Group> & { children?: Node[] }) => {
    if (parent.order) return;

    parent.nodesCache =
      (parent.nodes || parent.children)?.map((node) => {
        node._id = v4();
        // node.parentId = node._id
        if (node.type === 'Group') {
          change(node as unknown as Dehydrate<typeof Group>);
        }
        node.parentId = node._id;
        return node;
      }) ?? [];

    parent.order = ((parent.nodes || parent.children) as Node[])?.map((node) => node._id) ?? [];
    delete parent.nodes;
    delete parent.children;
  };

  change(node);
}

export function assert(condition: boolean | object | string | undefined | null, message?: string): asserts condition {
  if (!condition) {
    throw new Error(message ?? 'Assertion failed');
  }
}

export const copyNodes = (nodes: Node[], board: Board) => {
  board.copied = nodes.map((n) => {
    return n.dehydrateAsCopy();
  });

  navigator.clipboard.writeText(JSON.stringify(board.copied));
};
